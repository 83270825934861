@font-face {
    font-family: PoppinsRegular;
    src: url('../fonts/Poppins-Regular.otf');
    font-display: swap;
}
@font-face {
    font-family: PoppinsBold;
    src: url('../fonts/Poppins-Bold.otf');
    font-display: swap;
}
@font-face {
    font-family: BaksoSapi;
    src: url('../fonts/BaksoSapi.otf');
    font-display: swap;
}
@font-face {
    font-family: PoppinsMedium;
    src: url('../fonts/Poppins-Medium.otf');
    font-display: swap;
}

// Font Family
$poppinsregular: 'PoppinsRegular', sans-serif;
$bakso: 'BaksoSapi', sans-serif;
$poppinsbold:'PoppinsBold', sans-serif;
$poppinsmedium:'PoppinsMedium', sans-serif;


// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: #F2AA41;
$secundary-color: #51300B;
$third-color:#878787;  
$fourth-color:#ecd6b7;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
