.subscribe-form-2 {
  display: flex;
  flex-direction: column;

  input {
    background: transparent;
    border: none;
    height: 45px;
    padding: 2px 50px 2px 0;
    font-size: 14px;
    color: $theme-color;
    border: 1.3px solid $theme-color;
    padding-left: 1rem;
    margin-bottom: 1rem;
    &::placeholder{
      color:$third-color;
    }
    @media #{$xs-layout} {
      padding-left: 1rem;
    }
  }
  .mc-news {
    display: none;
  }

    button {
      margin-left: auto;
      background: $theme-color;
      border: none;
      height: 45px;
      padding: 0;
      font-size: 30px;
      color: #fff;
      transition: 0.3s;
      font-size: 22.18px;
      font-family: $poppinsregular;
      padding-right: 1rem;
      padding-left: 1rem;
      @media #{$xs-layout} {
        font-size: 20px;
      }
      &:hover {
        color:$theme-color;
      }
    }
}

.subscribe-area{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 320/71;
  .bor {
    border: 1px solid $theme-color;
    width: 100%;
    height: inherit;
    position: absolute;
  }
  .subscribe-container{
    position: relative;
    height: 25rem;
  }
  h3{
    color: #fff;
    font-size:40px;
    font-family: $bakso;
    line-height: 50px;
  }
  #italic{
    color: $theme-color;
    font-family: Montserrat-BoldItalic;
    font-size:40px;
  }
}

.subscribe-area-3.pb-100 {
  @media #{$xs-layout} {
    padding-bottom: 60px !important;
  }
}
